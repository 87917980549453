<template>
  <div id="printArea">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="3">
              <h4 class="text-uppercase">TBL Reading Data view</h4>
            </CCol>
            <CCol sm="8">
              <form v-on:submit.prevent="filterConsumption()">
                <CRow>
                  <CCol sm="2" class="pr-0">
                    <select class="form-control" v-model="params.batch_no_id">
                      <option value="">Please select</option>
                      <option :value="batchNoList.id" v-for="batchNoList in batchNoLists">{{batchNoList.batch_no}}</option>
                    </select>
                  </CCol>
                  <CCol sm="3" class="pr-0">
                    <CInput
                        required
                        type="month"
                        v-model="params.month"
                    />
                  </CCol>

                  <CCol sm="2">
                    <button type="submit" class="btn btn-success w-100">Submit</button>
                  </CCol>
                  <CCol sm="2">
                    <button type="button" @click="saveToDB()" class="btn btn-warning">
                      Save to DB
                    </button>
                  </CCol>
                </CRow>
              </form>
            </CCol>
          </CRow>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr class="text-center">
                <th class="font-weight-bold">Account No</th>
                <th class="font-weight-bold">WRCode</th>
                <th class="font-weight-bold">MTRWDTH</th>
                <th class="font-weight-bold">NBill</th>
                <th class="font-weight-bold">PrevRead</th>
                <th class="font-weight-bold">PrevDate</th>
                <th class="font-weight-bold">PresRead</th>
                <th class="font-weight-bold">PresDate</th>
                <th class="font-weight-bold">Consum</th>
                <th class="font-weight-bold">WaterBill</th>
                <th class="font-weight-bold">Excise</th>
                <th class="font-weight-bold">Total</th>
                <th class="font-weight-bold">RiCode</th>
                <th class="font-weight-bold">BatchNo</th>
                <th class="font-weight-bold">Status</th>
                <th class="font-weight-bold">EntBy</th>
                <th class="font-weight-bold">EntryDate</th>
                <th class="font-weight-bold">NE</th>
                <th class="font-weight-bold">MonthEndDate</th>
                <th class="font-weight-bold">EntryDate2</th>
              </tr>
              </thead>
              <tbody v-if="readings.length">
              <tr
                  class="text-center"
                  v-for="consumption in readings"
                  :key="consumption.id"
              >
                <td>{{ consumption.customer_account.account_number }}</td>
                <td>{{ consumption.rate_id }}</td>
                <td>{{ consumption.mtr_wdth }}</td>
                <td>{{ consumption.non_domestic_bill }}</td>
                <td>{{ consumption.previous_read }}</td>
                <td>{{ consumption.previous_date }}</td>
                <td>{{ consumption.present_read }}</td>
                <td>{{ consumption.present_date }}</td>
                <td>{{ consumption.consumption }}</td>
                <td>{{ consumption.water_bill }}</td>
                <td>{{ consumption.excise }}</td>
                <td>{{ consumption.total }}</td>
                <td>{{ consumption.meter_inspector_id }}</td>
                <td>{{ consumption.batch_no_id }}</td>
                <td>{{ consumption.status }}</td>
                <td>{{ consumption.created_by }}</td>
                <td>{{ consumption.entry_date }}</td>
                <td>{{ consumption.ne }}</td>
                <td>{{ consumption.month_end_date }}</td>
                <td>{{ consumption.entry_date_2 }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="4">
                  <h5 class="text-center">Data Not Available.</h5>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  name: "ReadingGenerate",
  data: () => {
    return {
      batchNoLists:[],
      params: {
        start_date: moment().clone().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().clone().endOf('month').format('YYYY-MM-DD'),
        batch_no_id: '',
        month: '',
        page: 1,
        isStore: false
      },
      isMobile: Boolean,
      isMobileOnly: Boolean,
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "METER CONSUMPTIONS",
        path: "/meter-consumptions",
        isActive: false
      }, {text: "Reading Data View", path: "", isActive: true}]
    };
  },

  computed: {
    ...mapGetters("Reading", [
      "readings",
      "rows",
      "perPage"
    ]),
  },
  methods: {
    getBatchNo(){
      axios.get(`/admin/batch-no/lists`).then((res) => {
        this.batchNoLists = res.data;
      }).catch((err) => {
        console.log(err);
      });
    },
    saveToDB() {
      this.params.isStore = true
      this.$store.dispatch("Reading/store", this.params);
    },
    filterConsumption() {
      this.$store.dispatch("Reading/getReadings", this.params);
    },

  },
  mounted() {
    this.getBatchNo()
    this.params.meterId = this.$route.params.id;
    this.$store.dispatch("Reading/getReadings", this.params);
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card {
  border: none;
  padding: 15px 15px 0;
}

</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;

          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
